import { roles } from 'const/roles';

export default [
  {
    pages: [
      {
        title: 'navigators',
        href: '/navigators',
        icon: 'Tractor',
        roles: [roles.user],
      },
      {
        title: 'attachedMachines',
        href: '/attached-machines',
        icon: 'Machines',
        roles: [roles.user],
      },
      {
        title: 'plots',
        href: '/plots',
        icon: 'Plots',
        roles: [roles.user],
      },
      {
        title: 'baseStations',
        href: '/base-stations',
        icon: 'Station',
        roles: [roles.user],
      },
      {
        title: 'jobs',
        href: '/jobs',
        icon: 'Jobs',
        roles: [roles.user],
      },
      {
        title: 'workOrders',
        href: '/work-orders',
        icon: 'WorkOrders',
        roles: [roles.user],
      },
      {
        title: 'mainMenu',
        href: '/main-menu',
        icon: 'MainMenu',
        roles: [roles.distributor, roles.superAdmin],
      },
      {
        title: 'userHandling',
        href: '/user-handling',
        icon: 'UserHandling',
        roles: [roles.distributor, roles.superAdmin],
      },
      {
        title: 'distributorHandling',
        href: '/distributor-handling',
        icon: 'DistributorHandling1',
        roles: [roles.superAdmin],
      },
      {
        title: 'baseStationHandling',
        href: '/base-station-handling',
        icon: 'Stations',
        roles: [roles.distributor, roles.superAdmin],
      },
    ],
  },
  // {
  //   title: 'Pages',
  //   pages: [
  //     {
  //       title: 'Overview',
  //       href: '/overview',
  //       icon: HomeIcon,
  //     },
  //     {
  //       title: 'Dashboards',
  //       href: '/dashboards',
  //       icon: DashboardIcon,
  //       children: [
  //         {
  //           title: 'Default',
  //           href: '/dashboards/default',
  //         },
  //         {
  //           title: 'Analytics',
  //           href: '/dashboards/analytics',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Management',
  //       href: '/management',
  //       icon: BarChartIcon,
  //       children: [
  //         {
  //           title: 'Soil maps',
  //           href: '/management/soil-maps',
  //           icon: FilterHdr,
  //         },
  //         {
  //           title: 'Plant data',
  //           href: '/management/plant-data',
  //           icon: LocalFlorist,
  //         },
  //         {
  //           title: 'Yield data',
  //           href: '/management/yield-data',
  //           icon: ClearAll,
  //         },
  //         {
  //           title: 'Lab data',
  //           href: '/management/lab-data',
  //           icon: Colorize,
  //           children: [
  //             {
  //               title: 'Chemical analysis',
  //               href: '/management/lab-data/chemical-analysis',
  //               children: [
  //                 {
  //                   title: 'Nitrogen management',
  //                   href: '/management/lab-data/chemical-analysis/nitrogen-management',
  //                 },
  //                 {
  //                   title: 'Carbon management',
  //                   href: '/management/lab-data/chemical-analysis/carbon-management',
  //                 },
  //                 {
  //                   title: 'PK management',
  //                   href: '/management/lab-data/chemical-analysis/pk-management',
  //                 },
  //                 {
  //                   title: 'Ca, S, Mg management',
  //                   href: '/management/lab-data/chemical-analysis/ca-s-mg-management',
  //                 },
  //                 {
  //                   title: 'Micronutrient management',
  //                   href: '/management/lab-data/chemical-analysis/micronutrient-management',
  //                 },
  //                 {
  //                   title: 'Ratio management',
  //                   href: '/management/lab-data/chemical-analysis/ratio-management',
  //                 },
  //               ],
  //             },
  //             {
  //               title: 'Mechanical analysis',
  //               href: '/management/lab-data/mechanical-analysis',
  //               children: [
  //                 {
  //                   title: 'Soil classification',
  //                   href: '/management/lab-data/mechanical-analysis/soil-classification',
  //                 },
  //                 {
  //                   title: 'Field water capacity',
  //                   href: '/management/lab-data/mechanical-analysis/field-water-capacity',
  //                 },
  //                 {
  //                   title: 'Water infiltration',
  //                   href: '/management/lab-data/mechanical-analysis/water-infiltration',
  //                 },
  //                 {
  //                   title: 'Bulk density',
  //                   href: '/management/lab-data/mechanical-analysis/bulk-density',
  //                 },
  //                 {
  //                   title: 'Porosity',
  //                   href: '/management/lab-data/mechanical-analysis/porosity',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           title: 'Season',
  //           href: '/management/season',
  //           icon: CalendarTodayOutlinedIcon,
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/calendar',
  //       icon: CalendarTodayIcon,
  //     },
  //     {
  //       title: 'Settings',
  //       href: '/settings',
  //       icon: SettingsIcon,
  //       children: [
  //         {
  //           title: 'Profile',
  //           href: '/settings/profile',
  //           icon: PersonIcon,
  //         },
  //         {
  //           title: 'Organizations',
  //           href: '/settings/organizations',
  //           icon: Domain,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import theme from './theme';
import routes from './routes';
import {
  GoogleAnalytics,
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';

const history = createBrowserHistory();
const queryClient = new QueryClient();

const App = () => {

  const [mounted, setMounted] = useState('');

  if (!mounted) {
    queryClient.setQueryData('getUserData', JSON.parse(localStorage.getItem('userData')));
    setMounted(true);
  }

  return (
    <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <GoogleAnalytics />
              {renderRoutes(routes)}
              <ReactQueryDevtools initialIsOpen={false} />
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;

import React, { useState } from 'react';
import PlotsMap from './PlotsMap';
import Sidebar from 'components/Sidebar/Sidebar';
import { useQuery } from 'react-query';
import { getUserMaps } from 'endpoints/fieldMaps';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CircularProgress } from '@mui/material';
import AddShpModal from './addShpModal';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  bottomButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: 0,
    width: '100%',
    marginTop: 'auto',
    height: '48px',
    margin: '30px 0 30px 0',
  },
  titleBox: {
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: 'white',
    marginBottom: '20px',
  },
  accordion: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  accordionSummary: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
  },
  accordionDetails: {
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
  },
  accordionButtonStyle: {
    border: '1px solid gray',
    borderRadius: '5px',
    marginTop: '10px',
  },
}));

const loaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const Plots = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [plotData, setPlotData] = useState('');

  const userId = JSON.parse(localStorage.getItem('userData')).id;

  const { data, error, isLoading, refetch } = useQuery(['getUserMaps'], () =>
    getUserMaps(userId)
  );

  const handlePlotClick = plotData => {
    setSelectedPlot(plotData);
  };

  const handleExpandClick = event => {
    event.stopPropagation();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteModalOpen = plot => {
    setDeleteModalOpen(true);
    setPlotData(plot);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
  };

  const handleEdit = plot => {
    setUpdateModalOpen(true);
    setPlotData(plot);
  };

  if (isLoading) {
    return (
      <div style={loaderStyle}>
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  return (
    <>
      <Sidebar>
        <Box display="flex" flexDirection="column" height="100%">
          <Box className={classes.titleBox}>
            <Typography variant="h6">Parcele</Typography>
          </Box>
          {data &&
            data.map(plot => (
              <Accordion key={plot.id} className={classes.accordion}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon onClick={() => handlePlotClick(plot.geoData)} />}
                  onClick={handleExpandClick}
                >
                  <Typography>{plot.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Button
                    onClick={() => handleDeleteModalOpen(plot)}
                    className={classes.accordionButtonStyle}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => handleEdit(plot)}
                    className={classes.accordionButtonStyle}
                  >
                    Edit
                  </Button>
                </AccordionDetails>
              </Accordion>
            ))}
          <Button
            variant="contained"
            className={classes.bottomButton}
            endIcon={<CloudUploadIcon />}
            onClick={handleOpen}
          >
            Dodaj .shp fajl
          </Button>
        </Box>
      </Sidebar>
      <PlotsMap selectedPlot={selectedPlot} />
      <AddShpModal open={open} onClose={handleClose} refetch={refetch} />
      <DeleteModal
        open={deleteModalOpen}
        isOpen={deleteModalOpen}
        onClose={handleDeleteModalClose}
        plotData={plotData}
        refetch={refetch}
      />
      <EditModal
        open={updateModalOpen}
        isOpen={updateModalOpen}
        onClose={handleUpdateModalClose}
        plotData={plotData}
        refetch={refetch}
      />
    </>
  );
};

export default Plots;

import axiosInstance from '.';

export const getUserMaps = async userId => {
  return axiosInstance.get(`/fields/user/${userId}`).then(res => res.data);
};

export const uploadMapFiles = async params => {
  return axiosInstance
    .post('/fields/file/upload/', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*',
      },
    })
    .then(res => res.data);
};

export const createMap = async(name, size, userId, geoData) => {
  const data = { name, size, userId, geoData };
  return axiosInstance.post('/fields/create', data).then(res => res.data);
};

export const deleteMap = async mapId => {
  return axiosInstance.delete(`/fields/${mapId}`).then(res => res.data);
};

export const updateMap = async(mapId, name, size, userId, geoData) => {
  const data = { name, size, userId, geoData };
  return axiosInstance
    .put(`/fields/${mapId}/update`, { data })
    .then(res => res.data);
};

import React, { useRef } from 'react';
import { Box, Button, Grid, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import newMachine from './newMachineProps';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createMachine, updateMachine } from 'endpoints/machines';
import { hangingTypes, machineTypes } from 'const/roles';
import carriedAttached from '../../../assets/images/machines/carriedAttached.png';
import carriedSprayer from '../../../assets/images/machines/carriedSprayer.png';
import carriedSpreader from '../../../assets/images/machines/carriedSpreader.png';
import carriedDrill from '../../../assets/images/machines/carriedDrill.png';
import draggedAttached from '../../../assets/images/machines/draggedAttached.png';
import draggedSprayer from '../../../assets/images/machines/draggedSprayer.png';
import draggedDrill from '../../../assets/images/machines/draggedDrill.png';
import draggedSpreader from '../../../assets/images/machines/draggedSpreader.png';

export default function CreateMachineModal({
  modalOpen,
  closeModal,
  selectedMachine,
}) {
  const { t } = useTranslation('form');
  const formRef = useRef();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    selectedMachine ? updateMachine : createMachine,
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('isRequired')),
    machineType: Yup.string().required(t('isRequired')),
    section: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    width: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    workingWidth: Yup.string().required(t('isRequired')),
    hangingType: Yup.string().required(t('isRequired')),
    sideDrift: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    distanceH: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    distanceL: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    startTime: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    stopTime: Yup.number(t('mustBeNumber'))
      .positive(t('mustBePositive'))
      .integer(t('mustBeInteger'))
      .test('isWholeNumber', t('mustBeInteger'), val =>
        /^\d*[1-9]\d*$/.test(val)
      )
      .required(t('isRequired')),
    work: Yup.string().required(t('isRequired')),
  });

  const handleSubmit = async machine => {
    mutate(machine, {
      onSuccess: () => {
        const message = selectedMachine
          ? t('updatedMachine')
          : t('createdMachine');
        toast.success(message);
        queryClient.refetchQueries(['getUserMachines']);
        closeModal();
      },
    });
  };

  const resolvePicture = (hangingType, machineType) => {
    console.log(hangingType, machineType);
    if (hangingType === 'DRAGGED') {
      if (machineType === 'SEED_DRILL') {
        return draggedDrill;
      } else if (machineType === 'SPREADER') {
        return draggedSpreader;
      } else if (machineType === 'SPRAYER') {
        return draggedSprayer;
      } else if (machineType === 'ATTACHMENT') {
        return draggedAttached;
      }
    } else if (hangingType === 'CARRIED') {
      if (machineType === 'SEED_DRILL') {
        return carriedDrill;
      } else if (machineType === 'SPREADER') {
        return carriedSpreader;
      } else if (machineType === 'SPRAYER') {
        return carriedSprayer;
      } else if (machineType === 'ATTACHMENT') {
        return carriedAttached;
      }
    }
  };

  const initialValues = selectedMachine ? selectedMachine : newMachine;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {formik => (
          <Dialog
            open={modalOpen}
            onClose={closeModal}
            fullscreen="true"
            maxWidth="xl"
          >
            <DialogTitle>
              {selectedMachine ? t('machineChange') : t('machineCreate')}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid container item xs={12} md={6} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('name')}
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('machineType')}
                      variant="outlined"
                      fullWidth
                      select
                      name="machineType"
                      value={formik.values.machineType}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.machineType &&
                        Boolean(formik.errors.machineType)
                      }
                      helperText={
                        formik.touched.machineType && formik.errors.machineType
                      }
                    >
                      {machineTypes.map(item => (
                        <MenuItem key={item.type} value={item.type}>
                          {t(`machineTypes.${item.type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('section')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="section"
                      value={formik.values.section}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.section && Boolean(formik.errors.section)
                      }
                      helperText={
                        formik.touched.section && formik.errors.section
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('width')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="width"
                      value={formik.values.width}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.width && Boolean(formik.errors.width)
                      }
                      helperText={formik.touched.width && formik.errors.width}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('workingWidth')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="workingWidth"
                      value={formik.values.workingWidth}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.workingWidth &&
                        Boolean(formik.errors.workingWidth)
                      }
                      helperText={
                        formik.touched.workingWidth &&
                        formik.errors.workingWidth
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('hangingType')}
                      variant="outlined"
                      fullWidth
                      select
                      name="hangingType"
                      value={formik.values.hangingType}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.hangingType &&
                        Boolean(formik.errors.hangingType)
                      }
                      helperText={
                        formik.touched.hangingType && formik.errors.hangingType
                      }
                    >
                      {hangingTypes.map(item => (
                        <MenuItem key={item.type} value={item.type}>
                          {t(`hangingTypes.${item.type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('sideDrift')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="sideDrift"
                      value={formik.values.sideDrift}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.sideDrift &&
                        Boolean(formik.errors.sideDrift)
                      }
                      helperText={
                        formik.touched.sideDrift && formik.errors.sideDrift
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('distanceH')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="distanceH"
                      value={formik.values.distanceH}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.distanceH &&
                        Boolean(formik.errors.distanceH)
                      }
                      helperText={
                        formik.touched.distanceH && formik.errors.distanceH
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('distanceL')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="distanceL"
                      value={formik.values.distanceL}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.distanceL &&
                        Boolean(formik.errors.distanceL)
                      }
                      helperText={
                        formik.touched.distanceL && formik.errors.distanceL
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('startTime')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="startTime"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.startTime &&
                        Boolean(formik.errors.startTime)
                      }
                      helperText={
                        formik.touched.startTime && formik.errors.startTime
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('stopTime')}
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="stopTime"
                      value={formik.values.stopTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.stopTime &&
                        Boolean(formik.errors.stopTime)
                      }
                      helperText={
                        formik.touched.stopTime && formik.errors.stopTime
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('work')}
                      variant="outlined"
                      fullWidth
                      name="work"
                      value={formik.values.work}
                      onChange={formik.handleChange}
                      error={formik.touched.work && Boolean(formik.errors.work)}
                      helperText={formik.touched.work && formik.errors.work}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6} justify='center'>
                  <Box onClick={resolvePicture} textAlign="center">
                    <img src={resolvePicture(formik.values.hangingType, formik.values.machineType)} width="auto" height="500px" alt="" />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} variant="outlined" color="default">
                {t('cancel')}
              </Button>
              <Button
                onClick={formik.submitForm}
                variant="outlined"
                color="primary"
              >
                {t('save')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
}

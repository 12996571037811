import React, { useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { changePassword } from 'endpoints/users';
import { toast } from 'react-toastify';

export default function CreateUserModal({
  modalOpen,
  closeModal,
  selectedUser,
}) {
  const { t } = useTranslation('form');
  const formRef = useRef();

  const { isLoading, refetch } = useQuery(
    ['changePassword'],
    () => changePassword(selectedUser, formRef.current.values),
    {
      enabled: false,
      retry: false,
      onSuccess: () => {
        toast.success(t('passwordChanged'));
        closeModal();
      },
      onError: error => {
        toast.error(error.response.data.message);
      },
    },
  );

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, t('mustBeMinLength', { val: 8 }))
      .max(50, t('mustBeMinLength', { val: 50 }))
      .notOneOf([Yup.ref('oldPassword'), null], t('newPasswordIsSame'))
      .required(t('isRequired')),
  });

  const initialValues = {
    newPassword: '',
  };

  const handleSubmit = () => {
    refetch();
  };

  const generatePassword = () => {
    const chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 8; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {formik => (
        <Dialog open={modalOpen} onClose={closeModal} disableBackdropClick={isLoading} disableEscapeKeyDown={isLoading}>
          <DialogTitle>{t('passwordChange')}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('newPassword')}
                  variant="outlined"
                  fullWidth
                  name="newPassword"
                  type="input"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() =>
                    formik.setFieldValue('newPassword', generatePassword())
                  }
                  variant="outlined"
                  color="secondary"
                  fullWidth
                >
                  {t('generatePassword')}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeModal}
              disabled={isLoading}
              variant="outlined"
              color="default"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={formik.submitForm}
              disabled={isLoading}
              variant="outlined"
              color="primary"
            >
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

const newMachine = {
  machineType: 'SEED_DRILL',
  name: '',
  section: null,
  width: null,
  workingWidth: null,
  hangingType: 'DRAGGED',
  sideDrift: null,
  distanceH: null,
  distanceL: null,
  startTime: null,
  stopTime: null,
  work: '',
};

export default newMachine;
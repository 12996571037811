import React, { useRef } from 'react';
import { Button, Grid, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import newNavigator from './newNavigatorProps';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { updateNavigator, createNavigator } from 'endpoints/navigators';
import { toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

export default function CreateNavigatorModal({
  modalOpen,
  closeModal,
  selectedNavigator,
  navigatorUser,
}) {
  const { t } = useTranslation('form');
  const formRef = useRef();
  const queryClient = useQueryClient();

  const { isLoading, refetch } = useQuery(
    ['createNavigator'],
    () =>
      selectedNavigator
        ? updateNavigator(formRef.current.values)
        : createNavigator(formRef.current.values),
    {
      enabled: false,
      retry: false,
      onSuccess: () => {
        const message = selectedNavigator ? t('updatedNavigator') : t('createdNavigator');
        toast.success(message);
        closeModal();
        queryClient.refetchQueries(['navigators']);
      },
      onError: error => {
        toast.error(error.response.data.message);
      },
    }
  );

  const users = queryClient.getQueryData('users');

  const validationSchema = Yup.object().shape({
    serialNumber: Yup.string().required(t('isRequired')),
    fromDate: Yup.date().required(t('isRequired')),
    toDate: Yup.date()
      .min(Yup.ref('fromDate'), t('dateAfter'))
      .required(t('isRequired'))
      .typeError(t('validDate'))
      .test({
        name: 'afterDate',
        exclusive: false,
        message: t('dateAfter'),
        test: function(value) {
          return this.parent.fromDate.getTime() < value.getTime();
        },
      }),
    userId: selectedNavigator ? Yup.string().required(t('isRequired')) : null,
  });

  const handleSubmit = async navigator => {
    if (!selectedNavigator) {
      navigator.userId = navigatorUser;
    }
    refetch({ createNavigator: navigator });
  };
  const initialValues = selectedNavigator ? selectedNavigator : newNavigator;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {formik => (
        <Dialog
          open={modalOpen}
          onClose={closeModal}
          disableBackdropClick={isLoading}
          disableEscapeKeyDown={isLoading}
          disableEnforceFocus
        >
          <DialogTitle>
            {selectedNavigator ? t('navigatorChange') : t('navigatorCreate')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('serialNumber')}
                  variant="outlined"
                  fullWidth
                  name="serialNumber"
                  value={formik.values.serialNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.serialNumber &&
                    Boolean(formik.errors.serialNumber)
                  }
                  helperText={
                    formik.touched.serialNumber && formik.errors.serialNumber
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="sr"
                >
                  <DateField
                    label={t('validFrom')}
                    variant="outlined"
                    fullWidth
                    disabled
                    name="fromDate"
                    value={formik.values.fromDate}
                    onChange={val => formik.setFieldValue('fromDate', val)}
                    error={
                      formik.touched.fromDate && Boolean(formik.errors.fromDate)
                    }
                    helperText={
                      formik.touched.fromDate && formik.errors.fromDate
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="sr"
                >
                  <DateField
                    label={t('validTo')}
                    variant="outlined"
                    fullWidth
                    name="toDate"
                    value={formik.values.toDate}
                    onChange={val => formik.setFieldValue('toDate', val)}
                    error={
                      formik.touched.toDate && Boolean(formik.errors.toDate)
                    }
                    helperText={formik.touched.toDate && formik.errors.toDate}
                  />
                </LocalizationProvider>
              </Grid>
              {selectedNavigator ? (
                <Grid item xs={12} md={12}>
                  <TextField
                    label={t('user')}
                    variant="outlined"
                    fullWidth
                    name="userId"
                    select
                    value={formik.values.userId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userId && Boolean(formik.errors.userId)
                    }
                    helperText={formik.touched.userId && formik.errors.userId}
                  >
                    {users.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeModal}
              disabled={isLoading}
              variant="outlined"
              color="default"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={formik.submitForm}
              disabled={isLoading}
              variant="outlined"
              color="primary"
            >
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

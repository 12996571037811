import React from 'react';
import Sidebar from 'components/Sidebar';

export default function Navigators() {
  return (
    <>
      <Sidebar>Sidebar component</Sidebar>
      <p>Main content</p>
    </>
  );
}

import dayjs from "dayjs";

const newUser = {
  serialNumber: '',
  fromDate: dayjs(),
  toDate: dayjs().year(dayjs().year() + 1),
  userId: '',
};

export default newUser;

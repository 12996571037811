import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { DrawingManager } from '@react-google-maps/api';
import { useQuery } from 'react-query';
import { getUserMaps } from 'endpoints/fieldMaps';
import { Page } from 'components';
import Sidebar from 'components/Sidebar/Sidebar';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(3),
    width: '100%',
  },
  container: {
    display: 'flex',
    '& > *': {
      height: '100%',
    },
  },
}));

const MapComponent = props => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const classes = useStyles();

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCbJR44eIxzU5YsRiVh0mY91fmTcxxhCHc',
    libraries: ['drawing'],
  });

  const userId = JSON.parse(localStorage.getItem('userData')).id;
  console.log(userId);

  const { data, error, isLoading } = useQuery(
    ['getUserMaps'],
    () => getUserMaps(userId),
    {
      onSuccess: data => {
        console.log('success');
      },
      onError: error => {
        console.log('error');
      },
    },
  );

  console.log(data, error, isLoading);

  const onPolygonComplete = polygon => {
    // Handle polygon creation here
    console.log(polygon);
  };

  const userInfo = JSON.parse(localStorage.getItem('userData'));
  const userCountry = userInfo?.country;
  const userCity = userInfo?.city;
  console.log(userCountry, userCity);
  console.log(userInfo);

  const getCenter = callback => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: `${userCity}, ${userCountry}` },
      (results, status) => {
        if (status === 'OK') {
          const { lat, lng } = results[0].geometry.location;
          callback({ lat: lat(), lng: lng() });
        } else {
          console.log(
            'Geocode was not successful for the following reason: ' + status
          );
        }
      }
    );
  };

  useEffect(() => {
    if (map) {
      getCenter(setCenter);
    }
  }, [map]);

  const options = {
    mapTypeControl: false, // Disable map/satellite toggle button
    streetViewControl: false, // Disable street view button
  };
  const containerStyle = {
    width: '100%',
    height: '840px',
  };

  const onLoad = map => {
    setMap(map);
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_LEFT,
        drawingModes: [
          window.google.maps.drawing.OverlayType.MARKER,
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.POLYLINE,
          window.google.maps.drawing.OverlayType.RECTANGLE,
          window.google.maps.drawing.OverlayType.CIRCLE,
        ],
      },
      polygonOptions: {
        fillColor: '#00FF00',
        strokeColor: '#0000FF',
      },
    });
    drawingManager.setMap(map);
    window.google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      onPolygonComplete
    );
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  const onOverlayComplete = overlay => {
    console.log(overlay);
  };

  return (
    <>
      <Sidebar></Sidebar>
      <Page className={classes.root} title="Map component">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            map={map}
            center={center}
            zoom={10}
            options={options}
            // onUnmount={onUnmount}
          >
            <DrawingManager
              onPolygonComplete={onPolygonComplete}
              drawingControl={true}
              // drawingControlStyle={drawingControlStyles}
              // options={options}
              onLoad={onLoad}
              onOverlayComplete={onOverlayComplete}
              options={{
                controlPosition:
                  window.google.maps.ControlPosition.BOTTOM_CENTER,
              }}
            />
          </GoogleMap>
        )}
      </Page>
    </>
  );
};

export default MapComponent;

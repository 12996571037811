import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography,
  LinearProgress,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useMutation } from 'react-query';
import { uploadMapFiles, createMap } from 'endpoints/fieldMaps';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    minWidth: 800,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
  dropArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    height: 200,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  dropAreaActive: {
    backgroundColor: theme.palette.grey[200],
  },
  dropAreaIcon: {
    fontSize: 60,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(1),
  },
  dropAreaText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  dropAreaContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  browseButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fileNames: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  dialogActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    marginBottom: theme.spacing(5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`upload-tabpanel-${index}`}
      aria-labelledby={`upload-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FileItem = ({ file, onRemove }) => {
  const handleRemove = () => {
    onRemove(file);
  };

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="body1" style={{ marginRight: '10px' }}>
        {file.name}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRemove}
        size="small"
        ml={1}
      >
        Remove
      </Button>
    </Box>
  );
};

FileItem.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const AddMapFilesModal = ({ open, onClose, refetch }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [shpFiles, setShpFiles] = useState([]);
  const [shpFileNames, setShpFileNames] = useState([]);
  const [geoJSONFiles, setGeoJSONFiles] = useState([]);
  const [geoJSONFileNames, setGeoJSONFileNames] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [shpName, setShpName] = useState('');
  const [canSave, setCanSave] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!open) {
      setShpFiles([]);
      setShpFileNames([]);
      setGeoJSONFiles([]);
      setGeoJSONFileNames([]);
      setShpName('');
    }
  }, [open]);

  const handleShpFileChange = event => {
    const files = Array.from(event.target.files);

    files.forEach(file => {
      if (file.name.endsWith('.shp')) {
        setShpFiles(prevFiles => [...prevFiles, file]);
        setShpFileNames(prevFileNames => [...prevFileNames, file.name]);
      } else if (file.name.endsWith('.dbf')) {
        setShpFiles(prevFiles => [...prevFiles, file]);
        setShpFileNames(prevFileNames => [...prevFileNames, file.name]);
      } else {
        console.error('Please upload only .shp and .dbf files.');
      }
    });

    event.target.value = null;
  };

  const handleGeoJSONFileChange = event => {
    const files = Array.from(event.target.files);
    const fileNames = files.map(file => file.name);

    setGeoJSONFiles(prevFiles => [...prevFiles, ...files]);
    setGeoJSONFileNames(prevFileNames => [...prevFileNames, ...fileNames]);

    // Reset the file input value
    event.target.value = null;
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const fileNames = Array.from(files);
    if (tabValue === 0) {
      setShpFiles(Array.from(files));
      setShpFileNames(fileNames);
    } else if (tabValue === 1) {
      setGeoJSONFiles(Array.from(files));
      setGeoJSONFileNames(fileNames);
    }
    setDragging(false);
  };

  const handleDrag = event => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragEnd = event => {
    event.preventDefault();
    setDragging(false);
  };
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userId = userData.id;

  const mutation = useMutation(data => uploadMapFiles(data), {
    onSuccess: async data => {
      const name = shpName;
      const size = '10ha'; // hardkodirano za sada, ne znam sta radimo sa time jos.
      const geoData = data;

      try {
        const result = await createMap(name, size, userId, geoData);
        console.log(result); //  TODO
        toast.success('Plot added successfully!');
        refetch();
        onClose();
      } catch (error) {
        toast.error('Error');
        console.error(error); //  TODO
      }
    },
  });

  useEffect(() => {
    if (shpFiles.length) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [shpFiles]);

  const handleSave = async() => {
    // if (shpFiles.length === 0 || geoJSONFiles.length === 0) {
    //   console.error('Both .shp and GeoJSON files must be uploaded.');
    //   return;
    // }
    const formData = new FormData();
    shpFiles.forEach(file => {
      formData.append('shp_files', file);
    });
    geoJSONFiles.forEach(file => {
      formData.append('geojson_files', file);
    });

    try {
      await mutation.mutateAsync(formData);
      console.log(mutation.data); // ???
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="upload-modal-title"
        maxWidth="md"
      >
        <DialogTitle id="upload-modal-title" style={{ textAlign: 'center' }}>
          <Typography variant="h2">Upload Files</Typography>
        </DialogTitle>
        {mutation.isLoading && <LinearProgress />}
        <DialogContent>
          <Box width="100%">
            <div className={classes.root}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Upload .shp File" id="upload-tab-0" />
                <Tab label="Upload GeoJSON" id="upload-tab-1" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Box width="100%">
                  <TextField
                    id="shp-file-name"
                    label="Name"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                    onChange={e => setShpName(e.target.value)}
                  />
                </Box>
                <div
                  className={`${classes.dropArea} ${dragging &&
                    classes.dropAreaActive}`}
                  onDrop={handleDrop}
                  onDragOver={handleDrag}
                  onDragLeave={handleDragEnd}
                >
                  <CloudUploadIcon className={classes.dropAreaIcon} />
                  <span className={classes.dropAreaText}>
                    Drag and drop .shp and .dbf files here
                  </span>
                  <input
                    accept=".shp, .dbf"
                    className={classes.fileInput}
                    id="shp-file"
                    type="file"
                    multiple
                    onChange={handleShpFileChange}
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <label htmlFor="shp-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Browse
                      </Button>
                    </label>
                    {shpFileNames.length > 0 && (
                      <Box ml={2}>
                        <Typography variant="body1">
                          {shpFiles.map((file, index) => (
                            <FileItem
                              key={index}
                              file={file}
                              onRemove={() => {
                                setShpFiles(shpFiles.filter(f => f !== file));
                                setShpFileNames(
                                  shpFileNames.filter(
                                    name => name !== file.name
                                  )
                                );
                              }}
                            />
                          ))}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Box width="100%">
                  <TextField
                    id="geojson-file-name"
                    label="Name"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                  />
                </Box>
                <div
                  className={`${classes.dropArea} ${dragging &&
                    classes.dropAreaActive}`}
                  onDrop={handleDrop}
                  onDragOver={handleDrag}
                  onDragLeave={handleDragEnd}
                >
                  <CloudUploadIcon className={classes.dropAreaIcon} />
                  <span className={classes.dropAreaText}>
                    Drag and drop GeoJSON files here
                  </span>
                  <input
                    accept=".json"
                    className={classes.fileInput}
                    id="geojson-file"
                    type="file"
                    multiple
                    onChange={handleGeoJSONFileChange}
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    <label htmlFor="geojson-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Browse
                      </Button>
                    </label>
                    {geoJSONFileNames.length > 0 && (
                      <Box ml={2}>
                        <Typography variant="body1">
                          {geoJSONFiles.map((file, index) => (
                            <FileItem
                              key={index}
                              file={file}
                              onRemove={() => {
                                setGeoJSONFiles(
                                  geoJSONFiles.filter(f => f !== file)
                                );
                                setGeoJSONFileNames(
                                  geoJSONFileNames.filter(
                                    name => name !== file.name
                                  )
                                );
                              }}
                            />
                          ))}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </div>
              </TabPanel>
            </div>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!canSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={mutation.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

AddMapFilesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default AddMapFilesModal;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { deleteNavigator } from 'endpoints/navigators';
import { toast } from 'react-toastify';

export default function DeleteNavigatorDialog({
  modalOpen,
  selectedNavigator,
  handleClose,
}) {
  const queryClient = useQueryClient();
  const { isLoading, refetch } = useQuery(
    'deleteNavigator',
    () => deleteNavigator(selectedNavigator.id), {
      manual: true,
      retry: false,
      refetchOnMount: false,
      enabled: false,
      onError: error => {
        toast.error(error.response.data.message);
      },
      onSuccess: () => {
        toast.success(t('deletedNavigator'));
        queryClient.refetchQueries(['navigators']);
        handleClose();
      },
    },
  );

  const { t } = useTranslation('form');
  return (
    <Dialog open={modalOpen} onClose={handleClose} disableBackdropClick={isLoading} disableEscapeKeyDown={isLoading}>
      <DialogTitle>{t('confirmDelete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectedNavigator && t('confirmDeleteNavigatorMessage', { serialNumber: selectedNavigator?.serialNumber })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading} variant="outlined" color="default">
          {t('cancel')}
        </Button>
        <Button
          onClick={refetch}
          disabled={isLoading}
          variant="outlined"
          color="primary"
          autoFocus
        >
          {t('clear')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

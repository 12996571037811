import CircularLoader from 'components/Loader/CircularLoader/CircularLoader';
import { getUserMachines } from 'endpoints/machines';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  Box,
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  IconButton,
  TableHead,
  Paper,
} from '@material-ui/core';
// import CreateMachineModal from './CreateMachineModal';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import CreateMachineModal from './CreateMachineModal';
import DeleteMachineModal from './DeleteMachineModal/DeleteMachineModal';

const AttachedMachines = ({ children }) => {
  const queryClient = useQueryClient();
  const userId = queryClient.getQueryData('getUserData')?.id;
  const { data, isLoading } = useQuery(
    'getUserMachines',
    () => getUserMachines(userId),
    { refetchOnWindowFocus: false },
  );

  const [selectedMachine, setSelectedMachine] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [machineForDeletion, setMachineForDeletion] = useState(null);

  const { t } = useTranslation('form');

  const openModal = machine => {
    setModalOpen(true);
    setSelectedMachine(machine);
  };
  const closeModal = () => {
    setModalOpen(false);
    setSelectedMachine(null);
  };
  const openDeletionModal = machine => {
    setDeleteModalOpen(true);
    setMachineForDeletion(machine);
  };
  const closeDeletionModal = () => {
    setDeleteModalOpen(false);
    setMachineForDeletion(null);
  };

  if (isLoading) {
    return <CircularLoader fullscreen />;
  }


  return (
    <Box width="100%" display="flex" alignContent="center">
      {modalOpen ? (
        <CreateMachineModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          selectedMachine={selectedMachine}
        />
      ) : null}
      <DeleteMachineModal
        modalOpen={deleteModalOpen}
        selectedMachine={machineForDeletion}
        handleClose={closeDeletionModal}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('machineType')}</TableCell>
              <TableCell>{t('hangingType')}</TableCell>
              <TableCell>{t('section')}</TableCell>
              <TableCell>{t('width')}</TableCell>
              <TableCell>{t('workingWidth')}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => openModal()} color="primary">
                  <AddCircleIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <Row
                key={row.id}
                row={row}
                openModal={openModal}
                openDeletionModal={openDeletionModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AttachedMachines;

function Row(props) {
  const { row, openModal, openDeletionModal } = props;

  const { t } = useTranslation('form');

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{row.name}</TableCell>
        <TableCell>{t(`machineTypes.${row.machineType}`)}</TableCell>
        <TableCell>{t(`hangingTypes.${row.hangingType}`)}</TableCell>
        <TableCell>{row.section}</TableCell>
        <TableCell>{row.width}</TableCell>
        <TableCell>{row.workingWidth}</TableCell>
        <TableCell align="right">
          <>
            <IconButton onClick={() => openModal(row)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => openDeletionModal(row)}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, TextField, Button } from '@material-ui/core';
import { useMutation } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import { updateMap } from 'endpoints/fieldMaps';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '24px',
    minWidth: '500px',
    borderRadius: '8px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
}));

const EditModal = ({ open, onClose, refetch, plotData }) => {
  const classes = useStyles();
  const name = plotData?.name;
  const size = plotData?.size;
  const geoData = `${plotData?.geoData}`;
  const userId = plotData?.userId;
  const mapId = plotData?.id;

  const [inputName, setInputName] = useState(name);
  const [isNameValid, setIsNameValid] = useState(true);

  const handleInputChange = event => {
    const name = event.target.value;
    setInputName(name);
  };

  const mutation = useMutation(
    () => updateMap(mapId, inputName, size, userId, geoData),
    {
      onSuccess: () => {
        try {
          toast.success('Plot updated successfully!');
          onClose();
          refetch();
        } catch (error) {
          toast.error('Error');
          console.error(error);
        }
      },
    }
  );

  const handleSave = async() => {
    if (inputName) {
      await mutation.mutateAsync(mapId, inputName);
      setInputName('');
      setIsNameValid(true);
      onClose();
    } else {
      setIsNameValid(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          Edit {name}
        </Typography>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={inputName}
          onChange={handleInputChange}
          error={!isNameValid}
          helperText={!isNameValid && 'Name is required'}
          sx={{ mt: 2 }}
          defaultValue={name}
        />
        <Box className={classes.buttonContainer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default EditModal;
